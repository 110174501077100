.shooting-stars-section {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-size: cover;
}

.shooting-stars-section span {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    animation: animate 3s linear infinite;
    z-index: 1;
}
.shooting-stars-section span::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 300px;
    height: 1px;
    background: linear-gradient(45deg, #fff, transparent);
}
@keyframes animate {
    0% {
        transform: rotate(315deg) translateX(0);
        opacity: 1;
    }
    70% {
        opacity: 1;
    }
    100% {
        transform: rotate(315deg) translateX(-5000px);
        opacity: 0;
    }
}
.shooting-stars-section span:nth-child(1) {
    top: 0;
    right: 0;
    left: initial;
    animation-delay: 0s;
    animation-duration: 3s;
}
.shooting-stars-section span:nth-child(2) {
    top: 0;
    right: 80px;
    left: initial;
    animation-delay: 0.2s;
    animation-duration: 5s;
}
.shooting-stars-section span:nth-child(3) {
    top: 80;
    right: 0px;
    left: initial;
    animation-delay: 0.4s;
    animation-duration: 4s;
}
.shooting-stars-section span:nth-child(4) {
    top: 0;
    right: 180px;
    left: initial;
    animation-delay: 0.6s;
    animation-duration: 3.5s;
}
.shooting-stars-section span:nth-child(5) {
    top: 0;
    right: 400px;
    left: initial;
    animation-delay: 0.8s;
    animation-duration: 4.5s;
}
.shooting-stars-section span:nth-child(6) {
    top: 0;
    right: 600px;
    left: initial;
    animation-delay: 1s;
    animation-duration: 5s;
}
.shooting-stars-section span:nth-child(7) {
    top: 300px;
    right: 0px;
    left: initial;
    animation-delay: 1.2s;
    animation-duration: 3.75s;
}
.shooting-stars-section span:nth-child(8) {
    top: 0px;
    right: 700px;
    left: initial;
    animation-delay: 1.4s;
    animation-duration: 3.25s;
}
.shooting-stars-section span:nth-child(9) {
    top: 0px;
    right: 1000px;
    left: initial;
    animation-delay: 0.75s;
    animation-duration: 4.25s;
}
.shooting-stars-section span:nth-child(10) {
    top: 0px;
    right: 450px;
    left: initial;
    animation-delay: 2.75s;
    animation-duration: 4.75s;
}
